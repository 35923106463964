import { useLocation } from "react-router-dom"
import {
  GridIcon,
  LightbulbIcon,
  StarIcon,
  WandIcon,
} from "../../assets/CustomIcons"
import { classNames } from "../../utils/utils"
import { Link, linkTrackers } from "../ui/Link"
import { current } from "@reduxjs/toolkit"

const Sidebar = () => {
  return (
    <div className="inset-y-0 flex w-20 flex-none flex-col justify-between overflow-y-auto px-4 py-5 lg:w-56">
      <HomeSidebar />
    </div>
  )
}

const HomeSidebar = () => {
  const { pathname } = useLocation()

  const adminNavigation = [
    {
      name: "Apps",
      to: "/developer/admin/app-management",
      icon: GridIcon,
      current: false,
      tracker: linkTrackers.NONE,
    },
    {
      name: "App Crawler",
      to: "/developer/admin/app-management-crawler",
      icon: WandIcon,
      current: true,
      tracker: linkTrackers.NONE,
    },
    {
      name: "Review Ads",
      to: "/developer/admin/ad-campaign-review",
      icon: LightbulbIcon,
      current: false,
      tracker: linkTrackers.NONE,
    },
    {
      name: "Review Apps",
      to: "/developer/admin/app-review",
      icon: LightbulbIcon,
      current: false,
      tracker: linkTrackers.NONE,
    },
    {
      name: "Review Sources",
      to: "/developer/admin/aggregate-reviews",
      icon: StarIcon,
      current: false,
      tracker: linkTrackers.NONE,
    },
  ]

  return (
    <nav className="overflow-y-scroll scrollbar-hide">
      <div className="gap-y-1">
        <p
          className="mt-5 px-2 text-center text-xs font-medium text-gray-500 lg:text-start"
          id="projects-headline"
        >
          Admin Tools
        </p>
        <div
          className="flex flex-col space-y-1"
          role="group"
          aria-labelledby="projects-headline"
        >
          {adminNavigation.map((item) => (
            <NavItem key={item.name} item={item} pathname={pathname} />
          ))}
        </div>
      </div>
    </nav>
  )
}

const NavItem = ({ item, pathname, onClick }) => (
  <Link
    key={item.name}
    variant="naked"
    tracker={item.tracker}
    to={item?.to}
    onClick={onClick}
  >
    <span
      className={classNames(
        item?.to === pathname
          ? "bg-blue-50 text-blue-500 hover:text-blue-600"
          : "text-gray-700 hover:bg-gray-50 hover:text-gray-900",
        "without-ring group flex items-center justify-center rounded-md border-none p-2 text-sm focus:outline-none focus:ring-0 lg:justify-start",
      )}
    >
      <item.icon
        className="mr-0 h-6 w-6 flex-shrink-0 lg:mr-4"
        aria-hidden="true"
      />
      <p className="hidden lg:flex">{item.name}</p>
    </span>
  </Link>
)

export default Sidebar
